.Element{
    width: 450px;
    height: 200px;
}
.content{
    padding: 25px;
    background-color: #253237;
}

.form{
    padding-top: 100px;
    margin: 0 auto;
    width: 500px;
    color: #4f4d4a;
}

.title{
    color: #ffff;
    padding: 20px;
}

input{
    outline-color: black;
}

.bouton{
    background-color: #475358;
    color: #ffff;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 10px black solid;
}

